import React, { Component } from "react";
import Popup from "reactjs-popup";
import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  handleClose: () => any;
  tryLogin: (id: any, pw: any) => any;
  changePopup: (value: any) => any;
  handleChange: (viewName: string) => any;
}

interface State {
  ID: string;
  PW: string;
}

export class Login extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      ID: "",
      PW: "",
    };
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>로그인</h5>
                      <span>LOGIN</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-menu">
                      <button className="mm-btn active">로그인</button>
                      <button
                        className="mm-btn join-link"
                        onClick={() => {
                          this.props.handleChange(popupView.reg); //회원가입
                        }}
                      >
                        회원가입
                      </button>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>아이디</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            placeholder="아이디"
                            onChange={({ target: { value } }) =>
                              this.setState({ ID: value })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>비밀번호</span>
                        </div>
                        <div className="infos">
                          <input
                            type="password"
                            placeholder="비밀번호"
                            onChange={({ target: { value } }) =>
                              this.setState({ PW: value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-grp">
                        <button
                          className="gray login-btn"
                          data-dismiss="modal"
                          onClick={() => {
                            if (
                              this.state.ID == null ||
                              this.state.ID == "" ||
                              this.state.PW == "" ||
                              this.state.PW == null
                            ) {
                              return;
                            }
                            this.props.tryLogin(this.state.ID, this.state.PW);
                          }}
                        >
                          <i className="fas fa-check-square"></i> 로그인
                        </button>
                        <button
                          className="gray join-link"
                          onClick={() => {
                            this.props.handleChange(popupView.reg); //회원가입
                          }}
                        >
                          <i className="fa fa-user-plus"></i> 회원가입
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
