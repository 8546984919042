import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";
import { UserService } from "../service/user.service";
import { ConvertDate, HelpStatus, ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  const RenderRef = () => {
    if (ref != null && ref !== "") {
      return (
        <Box margin={1}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color={"secondary"}
          >
            답변
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: ref }}></div>
        </Box>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_help_message_read(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_help_message_read(row._id);
            setOpen(!open);
          }}
          style={{ color: "#ffffff" }}
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {HelpStatus(row.status)}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
            </Box>

            {RenderRef()}
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  balances: any;
  mode: string;

}

export class InOut extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balances: [],
      mode: helpView.list,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.balanceService.get_deposit_and_withdraw().then((data: any) => {
      if (data.status === "success") {
        this.setState({ balances: data.balances });
      }
    });
  };

  render() {
    let balances = this.state.balances;

 
    const RenderList = () => {
      if (this.state.mode !== helpView.list) {
        return <></>;
      }
      return (
        <>
          <table className="bs-table with-depth">
            <thead>
              <tr>
                  <th>타입</th>
                <th>금액</th>
                <th>상태</th>
                <th>작성일</th>
              </tr>
            </thead>
            <tbody>
              {balances.map((info: any, index: any) => (
                <>
                  <tr
                    className="depth-click"
                  >
                    <td className="count-td">
                      {info.type === 'deposit' && <span style={{ color: '#03a9f4'}}>충전</span>}
                      {info.type === 'withdraw' && <span style={{ color: '#f44336'}}>환전</span>}                      
                    </td>
                    <td className="title-td">{ConverMoeny(info.balance)}</td>
                    <td className="date-td">

                      {info.status === 'already' && <span style={{ color: 'green'}}>완료</span>}
                      {info.status === 'cansel' && <span style={{ color: '#f44336'}}>취소</span>}
                      {info.status !== 'already' && info.status !== 'cansel'   && <span  style={{ color: '#ff9800'}}>대기</span>}
                    </td>
                    <td className="nav-td">{ConvertDate(info.regdate)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <div className="modal-footer">
            <div className="btn-grp">
            
            </div>
          </div>

        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>입/출금</h5>
                      <span>InOut</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-menu">
                      <button className="mm-btn active">입/출금</button>
                      <button
                        className="mm-btn join-link"
                        onClick={() => {
                          this.props.handleChange(popupView.notice); //공지사항
                        }}
                      >
                        공지사항
                      </button>
                    </div>
                    {RenderList()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
