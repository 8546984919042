import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  id: string;

  nick: string;
  pass: string;
  pass_check: string;
  phone1: string;
  phone2: string;
  phone3: string;
  phone: string;

  bankname: string;
  banknumber: string;
  bankowner: string;
  exchange_pw: string;
  code: string;
  showCode: boolean;
}

export class Reg extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      id: "",
      nick: "",
      pass: "",
      pass_check: "",
      phone1: "",
      phone2: "",
      phone3: "",
      phone: "",

      bankname: "",
      banknumber: "",
      bankowner: "",
      exchange_pw: "",
      code: "",
      showCode: false,
    };
  }

  componentDidMount() {
    this.userService.getRegDomain().then((data: any) => {
      if (data.status === "success") {
        this.setState({ code: data.code, showCode: false });
      } else {
        this.setState({ showCode: true });
      }
    });
  }

  
  

  handleCheckNick = () => {
    if (
      this.state.nick === undefined ||
      this.state.nick.length < 2 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "닉네임 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.regCheckNick(this.state.nick).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원 가입",
          message: "사용가능한 닉네임 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원 가입",
          message: "사용 불가능한 닉네임 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({ id: "" });
              },
            },
          ],
        });
        return;
      }
    });
  };

  handleCheck = () => {
    if (
      this.state.id === undefined ||
      this.state.id.length <= 3 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.regCheckID(this.state.id).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원 가입",
          message: "사용 할수있는 아이디 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원 가입",
          message: "사용 불가능한 아이디 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({ id: "" });
              },
            },
          ],
        });
        return;
      }
    });
  };

  handleReg = () => {

    if (
      !/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9])/.test(this.state.pass)
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "비밀번호는 특수문자 영문 숫자 조합으로 생성되어야합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (
      this.state.id === undefined ||
      this.state.id.length <= 3 ||
      20 <= this.state.id.length
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if (korean.test(this.state.id)) {
      confirmAlert({
        title: "회원 가입",
        message: "아이디는 한글을 사용할수 없습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (
      this.state.pass == null ||
      this.state.pass_check == null ||
      this.state.pass !== this.state.pass_check
    ) {
      confirmAlert({
        title: "회원 가입",
        message: "비밀번호를 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    
    let phone = this.state.phone;
    if (phone == null || phone.length <= 10) {
      confirmAlert({
        title: "회원 가입",
        message: "휴대폰 번호를 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.bankname == null) {
      confirmAlert({
        title: "회원 가입",
        message: "은행명을 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.banknumber == null) {
      confirmAlert({
        title: "회원 가입",
        message: "계좌 번호를  확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    if (this.state.bankowner == null) {
      confirmAlert({
        title: "회원 가입",
        message: "예금주 이름을 확인해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    this.userService
      .regUser({
        id: this.state.id.trim(),

        nick: this.state.nick,
        pw: this.state.pass,
        pass_check: this.state.pass_check,
        phone: phone,
        bankname: this.state.bankname,
        banknumber: this.state.banknumber,
        bankowner: this.state.bankowner,
        exchange_pw: this.state.bankowner,
        code: this.state.code,
      })
      .then((data: any) => {
        if (data.status === "alread_have_user") {
          confirmAlert({
            title: "회원 가입",
            message: "생성할수 없는 유저아이디 입니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else if (data.status === "cant_find_code") {
          confirmAlert({
            title: "회원 가입",
            message: "회원 가입코드를 확인해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });

          return;
        } else if (data.status === "already") {
          confirmAlert({
            title: "회원 가입",
            message: "사용할수 없는 닉네임 또는 아이디 입니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else {
          confirmAlert({
            title: "회원 가입",
            message: "회원 가입에 성공하였습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });

          return;
        }
      });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>회원가입</h5>
                      <span>SIGN UP</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-menu">
                      <button
                        className="mm-btn login-link"
                        onClick={() => {
                          this.props.handleChange(popupView.login); //로그인
                        }}
                      >
                        로그인
                      </button>
                      <button className="mm-btn active">회원가입</button>
                    </div>
                    {/* <div className="form-head">
                      <span className="title">
                        <i className="fa fa-caret-right"></i> 필수정보
                      </span>
                    </div> */}
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>아이디</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="아이디"
                            value={this.state.id}
                            onChange={(e: any) => {
                              this.setState({ id: e.target.value });
                            }}
                          />
                          <button className="mm-btn active"  onClick={()=> this.handleCheck()}>
                            <i className="fas fa-check-square"></i>중복확인
                          </button>
                          <p>* ( 아이디는 3 ~ 10글자 이여야 합니다. )</p>
                        </div>
                    

                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>닉네임</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="닉네임"
                            value={this.state.nick}
                            onChange={(e: any) => {
                              this.setState({ nick: e.target.value });
                            }}
                          />

                            
                          <button className="mm-btn active" onClick={()=> this.handleCheckNick()}>
                            <i className="fas fa-check-square"></i>중복확인
                          </button>

                        </div>

                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>비밀번호</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="비밀번호"
                            value={this.state.pass}
                            onChange={(e: any) => {
                              this.setState({ pass: e.target.value });
                            }}
                          />
                          <p>* ( 영문, 숫자, 특수문자가 들어가야 합니다. )</p>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>비밀번호확인</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="비밀번호 확인"
                            value={this.state.pass_check}
                            onChange={(e: any) => {
                              this.setState({ pass_check: e.target.value });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>은행</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="은행"
                            value={this.state.bankname}
                            onChange={(e: any) => {
                              this.setState({ bankname: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>예금주</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="예금주"
                            value={this.state.bankowner}
                            onChange={(e: any) => {
                              this.setState({ bankowner: e.target.value });
                            }}
                          />
                          <p>
                            * ( 입금과 출금시 사용하시는 실제 예금주명으로
                            기입하여 주시기 바랍니다 )
                          </p>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>계좌번호</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="계좌번호"
                            value={this.state.banknumber}
                            onChange={(e: any) => {
                              this.setState({ banknumber: e.target.value });
                            }}
                          />
                          <p>
                            ( 띄어쓰기와 - 없이 숫자로만 기입하여 주시기
                            바랍니다 )
                          </p>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>환전비번</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="환전비번"
                            value={this.state.exchange_pw}
                            onChange={(e: any) => {
                              this.setState({
                                exchange_pw: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>휴대폰</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="휴대폰"
                            value={this.state.phone}
                            onChange={(e: any) => {
                              this.setState({ phone: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {this.state.showCode && (
                        <div className="form-group">
                          <div className="labels">
                            <span className="dot"></span>
                            <span>추천인코드</span>
                          </div>
                          <div className="infos">
                            <input
                              placeholder="추천인코드"
                              value={this.state.code}
                              onChange={(e: any) => {
                                this.setState({ code: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="modal-footer">
                      <div className="btn-grp one-btn">
                        <button
                          onClick={() => {
                            this.handleReg();
                          }}
                        >
                          <i className="fas fa-check-square"></i> 회원가입
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        )}
      </Popup>
    );
  }
}
