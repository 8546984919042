import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";
import { UserService } from "../service/user.service";
import { ConvertDate, HelpStatus } from "../utility/help";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  const RenderRef = () => {
    if (ref != null && ref !== "") {
      return (
        <Box margin={1}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color={"secondary"}
          >
            답변
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: ref }}></div>
        </Box>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_help_message_read(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_help_message_read(row._id);
            setOpen(!open);
          }}
          style={{ color: "#ffffff" }}
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {HelpStatus(row.status)}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
            </Box>

            {RenderRef()}
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  helps: any;
  mode: string;

  title: string;
  contents: string;
  status: string;
  regdate: string;
  ref: string;
}

export class Help extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      mode: helpView.list,
      title: "",
      contents: "",
      status: "",
      regdate: "",
      ref: "",
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  handleSaveHelp = (title: string, contents: string) => {
    if (title == "") {
      confirmAlert({
        title: "고객센터",
        message: "타이틀을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }
    if (contents == "") {
      confirmAlert({
        title: "고객센터",
        message: "내용을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }

    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetNotices();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });

    this.props.handleClose();
  };

  render() {
    let helps = this.state.helps;
    console.log(helps);

    const RenderRef = () => {
      if (this.state.ref != null && this.state.ref !== "") {
        return (
          <Box margin={1}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              color={"secondary"}
            >
              답변
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: this.state.ref }}></div>
          </Box>
        );
      }
    };

    const RenderList = () => {
      if (this.state.mode !== helpView.list) {
        return <></>;
      }
      return (
        <>
          <table className="bs-table with-depth">
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>작성일</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              {helps.map((info: any, index: any) => (
                <>
                  <tr
                    className="depth-click"
                    onClick={() => {
                      service.do_help_message_read(info._id);
                      this.setState({
                        title: info.title,
                        contents: info.contents,
                        regdate: info.regdate,
                        status: info.status,
                        ref: info.ref != null ? info.ref.contents : "",
                        mode: helpView.view,
                      });
                    }}
                  >
                    <td className="count-td">{index + 1}</td>
                    <td className="title-td">
                      <div className="w-100">
                        <label className="cutted-text">{info.title}</label>
                      </div>
                    </td>
                    <td className="date-td">
                      <b className="bg-level-10"></b>
                      {ConvertDate(info.regDate)}
                    </td>
                    <td className="nav-td">{HelpStatus(info.status)}</td>
                  </tr>
                  <tr className="dropdown">
                    <td colSpan={6}>
                      <div className="mess-cont">
                        <div className="inner">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: info.contents,
                            }}
                          ></div>

                          {RenderRef()}
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <div className="modal-footer">
            <div className="btn-grp">
              <button
                onClick={() => {
                  this.setState({
                    mode: helpView.write,
                    title: "",
                    contents: "",
                    status: "",
                    regdate: "",
                    ref: "",
                  });
                }}
              >
                <i className="fas fa-check-square" aria-hidden="true"></i>{" "}
                글쓰기
              </button>
            </div>
          </div>

          {/* 목록 */}
          {/* <table className="Modal_info_table__1L6Ml" style={{ color: "#fff" }}>
            <thead>
              <tr>
                <th className="w-10">번호</th>
                <th className="w-50 ">제목</th>
                <th className="Modal_tablet_mode_hide__2vwY-">작성일</th>
                <th className="Modal_tablet_mode_hide__2vwY-">상태</th>
              </tr>
            </thead>
            <tbody>
              {helps.map((info: any, index: any) => (
                <tr
                  className="ng-scope"
                  onClick={() => {
                    service.do_help_message_read(info._id);
                    this.setState({
                      title: info.title,
                      contents: info.contents,
                      regdate: info.regdate,
                      status: info.status,
                      ref: info.ref != null ? info.ref.contents : "",
                      mode: helpView.view,
                    });
                    console.log(info);
                  }}
                >
                  <td>{index + 1}</td>
                  <td>
                    <div className="w-100">
                      <label className="cutted-text">{info.title}</label>
                    </div>
                  </td>
                  <td className="Modal_tablet_mode_hide__2vwY-">
                    <b className="bg-level-10"></b>
                    {ConvertDate(info.regdate)}
                  </td>
                  <td className="Modal_tablet_mode_hide__2vwY-">
                    {HelpStatus(info.status)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="modal-option-footer-btn mb-2">
            <div className="float-right">
              <button
                className="dark-web-btn"
                onClick={() => {
                  this.setState({
                    mode: helpView.write,
                    title: "",
                    contents: "",
                    status: "",
                    regdate: "",
                    ref: "",
                  });
                }}
              >
                <i className="fas fa-pencil-alt"></i> 글쓰기
              </button>
            </div>
            <div className="clearfix"></div>
          </div> */}
          {/* /목록 */}
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== helpView.view) {
        return <></>;
      }
      return (
        <>
          <div className="form-container">
            <div className="form-group">
              <div className="labels">
                <span className="dot"></span>
                <span>제목</span>
              </div>
              <div className="infos">
                <input type="text" readOnly value={this.state.title} />
              </div>
            </div>
            <div className="form-group no-label">
              <div className="infos">
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.contents }}
                ></div>
              </div>
            </div>


          </div>
          <div className="form-container">
            
          {
              this.state.ref && (
            <div className="form-group no-label">
              <div className="infos">
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.ref }}
                ></div>
              </div>
            </div>
              )
            }
          </div>

          <div className="modal-footer">
            <div className="btn-grp">
              <button
                onClick={() => {
                  this.setState({
                    mode: helpView.list,
                  });
                }}
              >
                <i className="fas fa-check-square" aria-hidden="true"></i> 목록
              </button>
            </div>
          </div>

          {/* <div className="textview-area">
            <div className="Modal_textview_area_header_2__3sQ9e">
              <label className="text-warning">
                <i className="far fa-comment-alt mr-2"></i> 고객센터 - Customer
              </label>
            </div>
            <div className="Modal_textview_area_header__1QgCe">
              <label>{this.state.title}</label>
              <span className="float-right">
                {ConvertDate(this.state.regdate)}
              </span>
            </div>
            <div className="scroll-wrapper">
              <div className="Modal_textview__2UO1S px-0">
                <div className="Home_editor_wrapper__2Ta5Z Home_read_editor__3A1I6">
                  <div
                    style={{
                      position: "relative",
                      outline: "none",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.contents }}
                    ></div>

                    {RenderRef()}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center w-100 py-1">
              <button
                className="dark-web-btn"
                onClick={() => {
                  this.setState({
                    mode: helpView.list,
                  });
                }}
              >
                <i className="fas fa-th-list"></i> 목록
              </button>
            </div>
          </div> */}
        </>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== helpView.write) {
        return <></>;
      }

      return (
        <>
          <div className="form-container">
            <div className="form-group">
              <div className="labels">
                <span className="dot"></span>
                <span>제목</span>
              </div>
              <div className="infos">
                <input
                  type="text"
                  placeholder="제목을 입력 하세요."
                  onChange={(e) =>
                    this.setState({
                      title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group no-label">
              <div className="infos">
                <textarea
                  rows={4}
                  cols={20}
                  maxLength={300}
                  placeholder="내용을 입력하세요"
                  onChange={(e) =>
                    this.setState({
                      contents: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="btn-grp">
              <button
                onClick={() => {
                  this.handleSaveHelp(this.state.title, this.state.contents);
                }}
              >
                <i className="fas fa-check-square" aria-hidden="true"></i>{" "}
                글쓰기
              </button>
            </div>
          </div>

          {/* <div className="textview-area">
            <div className="Modal_textview_area_header_2__3sQ9e">
              <label className="text-warning">
                <i className="fa fa-pencil-alt mr-2"></i> 고객센터 - Write
              </label>
            </div>
            <input
              className="Modal_textview_area_header__1QgCe text-white"
              placeholder="제목을 입력 하세요."
              onChange={(e) =>
                this.setState({
                  title: e.target.value,
                })
              }
            />
            <div className="Home_editor_wrapper__2Ta5Z Home_plain_editor__2iA1C">
              <div
                style={{
                  position: "relative",
                  outline: "none",
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                }}
              >
                <textarea
                  rows={4}
                  cols={20}
                  maxLength={300}
                  className="form-control ng-pristine ng-valid ng-valid-maxlength ng-touched"
                  placeholder="내용을 입력하세요"
                  onChange={(e) =>
                    this.setState({
                      contents: e.target.value,
                    })
                  }
                  style={{ background: "#222", border: "0px", color: "#fff" }}
                ></textarea>
              </div>
            </div>
            <div className="text-center w-100 py-1">
              <button
                className="dark-web-btn"
                onClick={() => {
                  this.setState({
                    mode: helpView.list,
                  });
                }}
              >
                <i className="fas fa-th-list"></i> 목록
              </button>
              <button
                className="danger-web-btn"
                onClick={() => {
                  this.handleSaveHelp(this.state.title, this.state.contents);
                }}
              >
                <i className="fas fa-paper-plane"></i> 글쓰기
              </button>
            </div>
          </div> */}
        </>
      );
    };
    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>고객센터</h5>
                      <span>HELP</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-menu">
                      <button className="mm-btn active">고객센터</button>
                      <button
                        className="mm-btn join-link"
                        onClick={() => {
                          this.props.handleChange(popupView.notice); //공지사항
                        }}
                      >
                        공지사항
                      </button>
                    </div>
                    {RenderList()}
                    {RenderWrite()}
                    {RenderView()}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="Modal_modal_wrapper__bmYcZ">
              <div className="Modal_modal_overlay__Ag4FI animate__animated animate__fadeIn"></div>
              <div className="Modal_modal_inner_wrapper__2J_Td">
                <div className="Modal_app_modal__WUCt9 animate__animated animate__bounceIn">
                  <div className="Modal_modal_header__3CHHh">
                    <img src="/images/logo.png" />
                    <button
                      className="Modal_close_modal__3PQ7Q"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    >
                      <i className="fas fa-times text-danger"></i>
                    </button>
                  </div>
                  <div className="Modal_modal_options__1UDxt">
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.deposit); //입금신청
                      }}
                    >
                      입금신청
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw); //출금신청
                      }}
                    >
                      출금신청
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.point); //금고
                      }}
                    >
                      금고
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.comp); //콤프
                      }}
                    >
                      콤프
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.user); //마이페이지
                      }}
                    >
                      마이페이지
                    </button>
                    <button className="Modal_active__2zgwL">고객센터</button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.notice); //공지사항
                      }}
                    >
                      공지사항
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.note); //쪽지
                      }}
                    >
                      쪽지
                    </button>
                  </div>
                  <div className="Mobile_modal_options__2LBvR"></div>
                  {RenderList()}
                  {RenderView()}
                  {RenderWrite()}
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
