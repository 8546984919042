import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export class Withdraw extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };
  }

  handleDoWithdraw = () => {
    if ((Number(this.state.balance) % 10000 ) !== 0) {
      confirmAlert({
        title: "출금",
        message: "출금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    if (Number(this.state.balance) < 30000) {
      confirmAlert({
        title: "출금",
        message: "최소 출금금액은 30,000원 입니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserWithdrawV2(this.state.balance).then((data) => {
      console.log(data);
      if (data.status === "success") {
        confirmAlert({
          title: "출금",
          message: "출금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "출금",
          message:
            "보유머니가 부족하거나 게임에 1회 접속하셨는지 확인바랍니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "출금",
          message: "대기중인 출금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "출금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };
  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>출금신청</h5>
                      <span>WITHDRAW</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleChange(popupView.none); //회원가입
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-menu">
                      <button
                        className="mm-btn deposit-link"
                        onClick={() => {
                          this.props.handleChange(popupView.deposit); //입금신청
                        }}
                      >
                        입금신청
                      </button>
                      <button className="mm-btn active">출금신청</button>
                    </div>
                    <div className="terms-use">
                      <div className="text-cont">
                        <div className="inner">
                          <p className="title">출금 신청시 주의사항 Notice</p>
                          <p>
                            1. 입금자명과 출금자명이 다를 경우 본인확인 요청이
                            있을 수 있습니다.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>보유머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>금고머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.point)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>아이디</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.id}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>예금주명</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.bankowner}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>회원은행</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.bankname}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>신청금액</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="0"
                            value={this.state.balance}
                            onFocus={() => this.setState({ balance: "" })}
                            onChange={(e) =>
                              this.setState({
                                balance: e.target.value,
                              })
                            }
                          />
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 10000
                                }`,
                              });
                            }}
                          >
                            1만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 50000
                                }`,
                              });
                            }}
                          >
                            5만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 100000
                                }`,
                              });
                            }}
                          >
                            10만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 500000
                                }`,
                              });
                            }}
                          >
                            50만원
                          </button>
                          <button
                            className="primary-web-btn mt-2"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 1000000
                                }`,
                              });
                            }}
                          >
                            100만원
                          </button>
                          <button
                            className="danger-web-btn mt-2"
                            onClick={() => {
                              this.setState({ balance: "0" });
                            }}
                          >
                            정정
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-grp">
                        <button onClick={this.handleDoWithdraw}>
                          <i className="fas fa-check-square"></i> 출금신청
                        </button>
                        <button
                          className="gray"
                          data-dismiss="modal"
                          onClick={() => {
                            this.props.handleClose();
                          }}
                        >
                          <i className="fa fa-window-close"></i> 취소하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        )}
      </Popup>
    );
  }
}
