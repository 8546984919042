import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";

import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  pass1: string;
  pass2: string;
}

export class User extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      pass1: "",
      pass2: "",
    };

    console.log(this.props.user);
  }

  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 3 || this.state.pass1.length > 20) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호정보가 동일해야합니다 ",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "비밀번호정보가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>마이페이지</h5>
                      <span>my page</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>아이디</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.id}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>보유머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>금고머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.point)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>예금주명</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.bankowner}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>회원은행</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.bankname}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>출금계좌번호</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.banknum}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>새 비밀번호</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="새 비밀번호"
                            value={this.state.pass1}
                            onChange={(e: any) => {
                              this.setState({ pass1: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>비밀번호 확인</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="비밀번호 확인"
                            value={this.state.pass2}
                            onChange={(e: any) => {
                              this.setState({ pass2: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-grp">
                        <button onClick={this.handleExchangeToPassword}>
                          <i className="fas fa-check-square"></i> 비밀번호변경
                        </button>
                        <button
                          className="gray"
                          data-dismiss="modal"
                          onClick={() => {
                            this.props.handleClose();
                          }}
                        >
                          <i className="fa fa-window-close"></i> 취소하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="Modal_modal_wrapper__bmYcZ">
              <div className="Modal_modal_overlay__Ag4FI animate__animated animate__fadeIn"></div>
              <div className="Modal_modal_inner_wrapper__2J_Td">
                <div className="Modal_app_modal__WUCt9 animate__animated animate__bounceIn">
                  <div className="Modal_modal_header__3CHHh">
                    <img src="/images/logo.png" />
                    <button
                      className="Modal_close_modal__3PQ7Q"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    >
                      <i className="fas fa-times text-danger"></i>
                    </button>
                  </div>
                  <div className="Modal_modal_options__1UDxt">
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.deposit); //입금신청
                      }}
                    >
                      입금신청
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw); //출금신청
                      }}
                    >
                      출금신청
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.point); //금고
                      }}
                    >
                      금고
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.comp); //콤프
                      }}
                    >
                      콤프
                    </button>
                    <button className="Modal_active__2zgwL">마이페이지</button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.help); //고객센터
                      }}
                    >
                      고객센터
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.notice); //공지사항
                      }}
                    >
                      공지사항
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.note); //쪽지
                      }}
                    >
                      쪽지
                    </button>
                  </div>
                  <div className="Mobile_modal_options__2LBvR"></div>
                  <table className="Modal_info_table__1L6Ml">
                    <tbody>
                      <tr>
                        <th className="border border-dark text-warning">
                          아이디
                        </th>
                        <td
                          className="w-75 text-left"
                          style={{ color: "#fff" }}
                        >
                          {this.props.user.id}
                        </td>
                      </tr>
                      <tr>
                        <th className="border border-dark text-warning">
                          보유머니
                        </th>
                        <td
                          className="w-75 text-left"
                          style={{ color: "#fff" }}
                        >
                          {this.props.user.balance}
                        </td>
                      </tr>
                      <tr>
                        <th className="border border-dark text-warning">
                          금고머니
                        </th>
                        <td
                          className="w-75 text-left"
                          style={{ color: "#fff" }}
                        >
                          {this.props.user.point}
                        </td>
                      </tr>
                      <tr>
                        <th className="border border-dark text-warning">
                          예금주명
                        </th>
                        <td
                          className="w-75 text-left"
                          style={{ color: "#fff" }}
                        >
                          {this.props.user.bankowner}
                        </td>
                      </tr>
                      <tr>
                        <th className="border border-dark text-warning">
                          회원은행
                        </th>
                        <td
                          className="w-75 text-left"
                          style={{ color: "#fff" }}
                        >
                          {this.props.user.bankname}
                        </td>
                      </tr>
                      <tr>
                        <th className="border border-dark text-warning">
                          출금계좌번호
                        </th>
                        <td
                          className="w-75 text-left"
                          style={{ color: "#fff" }}
                        >
                          {this.props.user.banknum}
                        </td>
                      </tr>
                      <tr>
                        <th className="border border-dark text-warning">
                          새 비밀번호
                        </th>
                        <td className="w-75 text-left">
                          <input
                            className="dark-form-control"
                            placeholder="새 비밀번호"
                            value={this.state.pass1}
                            onChange={(e: any) => {
                              this.setState({ pass1: e.target.value });
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="border border-dark text-warning">
                          비밀번호 확인
                        </th>
                        <td className="w-75 text-left">
                          <input
                            className="dark-form-control"
                            placeholder="비밀번호 확인"
                            value={this.state.pass2}
                            onChange={(e: any) => {
                              this.setState({ pass2: e.target.value });
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-center w-100 py-3">
                    <button
                      className="danger-web-btn"
                      onClick={this.handleExchangeToPassword}
                    >
                      <i className="fas fa-check"></i> 비밀번호변경
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
